.homeWrapper {
    display: block;
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}

.cardTitle {
    display: inline-block;
    position: relative;
    height: 100%;
    background-color: #C10000;
    color: #fff;
    height: 47px;
    min-width: 120px;
    line-height: 47px;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    box-sizing: border-box;
}

.newsWrapper {
    display: flex;
    width: 100%;
    font-size: 19px;
    margin: 6px 0;
    .newstitle {
        flex: 3;
        padding-right: 60px;
        color: #000;
        text-align: left;
    }

    .newsAuthor {
        flex: 1;
        color: #4457B4;
        text-align: center;
    }

    .newsCategory {
        flex: 1;
        color: #101010;
        text-align: right;
    }
}
@primary-color: #C10000;@link-color: #fff;@border-radius-base: 1px;