footer {
    display: block;
    height: 400px;
    width: 1200px;
    padding: 20px 60px;
    margin: 0 auto;
    margin-top: 80px;

    .topInfo {
        display: flex;
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);

        .leftAddress {
            flex: 3;

            &>h2 {
                color: #3A3A3A;
                font-size: 22px;
                line-height: 24px;
            }

            &>ul {
                list-style: none;
                margin: 0;
                padding: 0;
                margin-top: 17px;

                &>li {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    max-width: 500px;
                    word-break: keep-all;
                    margin: 10px 0;

                    &.address {
                        display: flex;
                        width: 100%;
                        &>label {
                            flex: 1;
                        }

                        &>p {
                            flex: 5;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .rightContact {
            flex: 2;

            &>h2 {
                color: #3A3A3A;
                font-size: 22px;
                line-height: 24px;
            }

            &>ul {
                list-style: none;
                margin: 0;
                padding: 0;
                margin-top: 17px;

                &>li {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 10px 0;
                }
            }
        }
    }

    .bottomInfo {
        display: block;
        margin: 20px 0;
        &>p {
            margin: 0;
            font-size: 17px;
            font-weight: 400;
            line-height: 2em;
        }
    }
}
@primary-color: #C10000;@link-color: #fff;@border-radius-base: 1px;