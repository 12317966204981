header {
    position: relative;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #C10000;
    color: #fff;

    .vn_header {
        display: block;
        height: 60px;
        line-height: 60px;
        width: 1200px;
        background: #C10000;
        margin: 0 auto;

        .vn_header_left {
            display: inline-block;
            width: 50%;
            height: 100%;
            text-align: left;

            .vn_logo {
                float: left;
                width: auto;
                height: 32px;
                line-height: 32px;
                font-size: 0;
                margin: 14px 0;
                margin-right: 12px;
            }

            &>h3 {
                float: left;
                font-size: 23px;
                font-weight: 400;
                color: #fff;
                margin: 0;
                padding: 0;
            }
        }

        .vn_header_right {
            clear: both;
            float: right;
            width: 50%;
            height: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            text-align: right;

            .vn_publish {
                position: relative;
                display: inline-block;
                text-align: left;
                width: 180px;
                height: 30px;
                line-height: 30px;
                border-bottom: 1px solid #fff;
                color: #fff;
                text-decoration: none;
                font-size: 18px;
                font-weight: 400;
                margin-right: 10px;
                padding-left: 20px;

                &::before {
                    position: absolute;
                    top: 8px;
                    left: 0;
                    content: "";
                    width: 14px;
                    height: 14px;
                    background: url(../../assets/images/pen.png) no-repeat;
                    background-size: 100% auto;
                    background-position: center center;
                }
            }

            .vn_sign {
                display: inline-block;
                color: #fff;
                text-decoration: none;
                width: 120px;
                height: 28px;
                text-align: center;
                line-height: 24px;
                font-size: 18px;
                font-weight: 400;
                margin: 0 10px;
                cursor: pointer;

                &:last-child {
                    border: 1px solid #fff;
                    border-radius: 5px;
                    padding: 1px 8px;
                }
            }

            .vn_name {
                display: inline-block;
                color: #fff;
                text-decoration: none;
                width: 120px;
                height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 30px;
                font-size: 18px;
                font-weight: 400;
                margin: 0 10px;
                cursor: pointer;
                transform: translateY(10px);
            }
        }
    }

    &::after {
        clear: both;
    }
}
@primary-color: #C10000;@link-color: #fff;@border-radius-base: 1px;